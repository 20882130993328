/**
 * Created by piotr.pozniak@thebeaverhead.com on 13/10/2018.
 */

import { hasSubscription } from "../apps/admin/helpers/organization";

const isMobile = () => {
  return window.innerWidth < 900;
};
/**
 *
 * @returns {Function}
 */
export const clearErrors = () => {
  return function (dispatch) {
    dispatch({ type: "CLEAR_ERRORS" });
  };
};

/**
 * returns the intercom application id. For prod, would return test for antyhing else.
 * @returns {string}
 */
const getAppId = () => {
  return process.env.INTERCOM_APP_ID;
};

/**
 *
 * @param user
 * @param organization
 * @param checkMobile if set to true, will launch intercom only on desktop
 * @returns {function(*): undefined}
 */
export const initializeIntercom = (user, organization, checkMobile = true) => {
  return (dispatch) => {
    if (!window.__DCE__DEV__) {
      if (checkMobile && isMobile()) {
        return;
      }

      const intercomData = {
        app_id: getAppId(),
      };

      if (user.token) {
        const subscription = hasSubscription(organization);

        intercomData.user_hash = user.model.intercom_hash;
        intercomData.email = user.model.email;
        intercomData.name = user.model.name;
        intercomData.company = {
          company_id: organization.model.uuid,
          name: organization.model.name,
          created: organization.model.created,
          plan: subscription ? subscription.name : "",
        };
      }

      window.Intercom("boot", intercomData);
    }
  };
};

/**
 *
 * @param user
 * @param organization
 * @param checkMobile if set to true, will launch intercom only on desktop
 * @returns {function(*): undefined}
 */
export const updateIntercom = (user, organization, checkMobile = true) => {
  return (dispatch) => {
    if (!window.__DCE__DEV__) {
      if (checkMobile && isMobile()) {
        return;
      }

      if (!user.model) {
        console.log("updateIntercom: user model empty", user.model);
        return;
      }

      const subscription = hasSubscription(organization);
      window.Intercom("update", {
        app_id: getAppId(),
        name: user.model.name,
        email: user.model.email,
        //   created_at: userModel.created,
        user_hash: user.model.intercom_hash,
        company: {
          company_id: organization.model.uuid,
          name: organization.model.name,
          created: organization.model.created,
          plan: subscription ? subscription.name : "",
        },
      });
    }
  };
};

/**
 *
 * @param userModel
 */
export const shutdownIntercom = (user) => {
  return (dispatch) => {
    if (!window.__DCE__DEV__) {
      window.Intercom("shutdown");
    }
  };
};

/**
 *
 * @param previewType
 * @returns {(function(*): void)|*}
 */
export const changePreviewType = (previewType) => {
  return (dispatch) => {
    dispatch({ type: "WIDGET_PREVIEW_MODE_CHANGE", previewType });
  };
};

/**
 *
 * @param previewType
 * @returns {(function(*): void)|*}
 */
export const toggleWidgetPreview = (isWidgetPreviewMode) => {
  return (dispatch) => {
    dispatch({ type: "WIDGET_PREVIEW_TOGGLE", isWidgetPreviewMode });
  };
};

/**
 *
 * @param previewType
 * @returns {(function(*): void)|*}
 */
export const toggleAdminNavigation = () => {
  return (dispatch) => {
    dispatch({ type: "ADMIN_NAVIGATION_TOGGLE" });
  };
};
/**
 *
 * @param previewType
 * @returns {(function(*): void)|*}
 */
export const dismissAlert = (id) => {
  return (dispatch) => {
    dispatch({ type: "APP_ALERT_DISMISS", id });
  };
};

/**
 *
 * @param widgetType
 * @param field
 * @param order
 * @returns {(function(*): void)|*}
 */
export const setWidgetsSorting = (widgetType, field, order) => {
  return (dispatch) => {
    dispatch({ type: "WIDGETS_SORTING_SET", widgetType, field, order });
  };
};
